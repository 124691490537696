<template>
  <img
    class="lazy-img"
    :src="src"
    :alt="alt"
    :class="[imageClass, imageState, skeletonColor]"
    :style="computedStyle"
    :width="canvasWidth"
    :height="canvasHeight"
    :data-width="imageWidth"
    :data-height="imageHeight"
    :data-state="imageState"
  />
</template>

<script>
export default {
  name: 'lazy-image',

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      required: false,
      default: '',
    },
    skeletonColor: {
      type: String,
      default: 'dark',
    },
    loadingImage: {
      type: String,
      default: require('@/assets/images/background_loading.jpg'),
    },
    errorImage: {
      type: String,
      default: require('@/assets/images/background_loading.jpg'),
    },
    imageErrorCallback: {
      type: Function,
      required: false,
      default: function() {},
    },
    imageSuccessCallback: {
      type: Function,
      required: false,
      default: function() {},
    },
    initialWidth: {
      type: [Number, String],
      default: 'auto',
    },
    initialHeight: {
      type: [Number, String],
      default: 'auto',
    },
  },

  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      canvasWidth: this.initialWidth,
      canvasHeight: this.initialHeight,
      imageState: 'loading',
      asyncImage: new Image(),
    };
  },

  computed: {
    computedStyle() {
      if (this.imageState === 'loading') {
        return '';
      }

      if (this.imageState === 'error') {
        return '';
      }

      if (this.imageState === 'loaded') {
        return '';
      }

      return '';
    },
  },

  created() {
    this.$nextTick(() => {
      this.fetchImage();
    });
  },

  methods: {
    // eslint-disable-next-line
    fetchImage(url) {
      this.asyncImage.onload = this.imageOnLoad;
      this.asyncImage.onerror = this.imageOnError;
      this.imageState = 'loading';
      this.asyncImage.src = this.src;
    },
    // eslint-disable-next-line
    imageOnLoad(success) {
      this.imageState = 'loaded';
      this.imageWidth = this.asyncImage.naturalWidth;
      this.imageHeight = this.asyncImage.naturalHeight;

      this.canvasWidth = 'auto';
      this.canvasHeight = 'auto';

      this.imageSuccessCallback();
    },
    // eslint-disable-next-line
    imageOnError(error) {
      this.imageState = 'error';
      this.imageErrorCallback();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.lazy-img {
  &.loading {
    @include skeleton-animation($gray-400);

    &.light {
      @include skeleton-animation($gray-200);
    }
  }
  &.error {
    filter: blur(5px);
  }
}
</style>
