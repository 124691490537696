const InfiniteScrollMixin = {
  data() {
    return {
      loadMoreEnabled: true,
      loadingMore: false,
      isRockBottom: false,
    };
  },

  methods: {
    rockBottom() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY + 300 >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },

    isBottomOf(element) {
      return element && element.offsetHeight + element.scrollTop === element.scrollHeight;
    },
  },
};

export default InfiniteScrollMixin;
